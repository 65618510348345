<template>
  <div class="shop">
    <div class="container">
      <h2>Магазин призов</h2>
      <div class="shop__content">
        <div class="shop__list">
          <div class="shop-item" v-for="(item, index) in info" :key="index">
            <div class="shop-item__img">
              <img :src="item.main_image" :alt="item.title" />
            </div>
            <div class="shop-item__name">{{ item.title }}</div>
            <div class="shop-item__text">
              <a href="https://disk.yandex.ru/d/YHHFHhid5LopYw" target="_blank">
                Как получить?
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "PrizesShop",
  data() {
    return {
      info: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageMerchData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "merch",
        },
      }).then((result) => {
        this.$store.commit("setMerchData", result.data);
        this.info = this.$store.state.storage.storageMerchData;
      });
    } else this.info = this.$store.state.storage.storageMerchData;
  },
};
</script>

<style lang="scss">
.shop {
  margin-top: 60px;
  h2 {
    margin-bottom: 30px;
  }
  &__content {
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px 150px;
    justify-items: center;
  }
  &-item {
    padding: 75px 35px 45px;
    box-shadow: 4px 4px 36px rgba(41, 41, 41, 0.05);
    border-radius: 15px;
    max-width: 390px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    &__img {
      max-width: 315px;
      max-height: 250px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      margin-top: 30px;
      max-width: 170px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      text-align: center;
    }
    &__text {
      margin-top: 15px;
      a {
        color: var(--btn-gray-color1);
      }
    }
  }
  @include adaptive(tablet-small) {
    &__list {
      gap: 20px;
    }
  }
  @include adaptive(phone) {
    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-item {
      max-width: 240px;
      padding: 20px;
      &__img {
        width: 170px;
        height: 170px;
      }
    }
  }
}
</style>
